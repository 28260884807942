import { Box, Button, Chip, CircularProgress, Grid, Typography, styled } from "@mui/material";
import { FiCamera, FiVideo, FiPlay } from "react-icons/fi";
import ReplayIcon from "@mui/icons-material/Replay";
import { BsDownload } from "react-icons/bs";
import { AiOutlineStop } from "react-icons/ai";
import { IncidentFileStatus } from "types/incident/file-status";
import React, { useEffect, useState } from "react";
import ProtectedView from "components/common/ProtectedView";
import { SCOPES } from "enums/scopes.enum";
import IotVisionToolTips from "components/common/IotVisionToolTips";
import { RETENTION_PERIOD_HAS_PASSED, VIDEO_IS_NO_LONGER_AVAILABLE } from "constants/taxirank-incidents.constants";
import { useSelector } from "react-redux";
import { Location } from "reducers/newLocation.reducer";
import { IncidentVideoAction, IncidentVideoStatus } from "enums/incident.enum";

const DISABLED_COLOR_CODE = "rgba(0, 0, 0, 0.26)";
const INCIDENT_FOOTAGE_LOADING = "Loading";
const INCIDENT_FOOTAGE_VIEW = "View Footage";


interface IncidentModalHeaderProps {
    incidentId?: number;
    location?: string;
    lga?: string;
    incidentFileStatus?: IncidentFileStatus;
    videoLinkLoading: boolean;
    requestVideoLoading: boolean;
    playVideoLoading: boolean;
    downloadVideoLoading: boolean;
    fileStatusFetching?: boolean;
    isRetentionPeriodExpired?: boolean;
    locationId: string;
    onViewFootageClick: () => void;
    onRequestVideo: ()=> Promise<void>;
    onDownloadVideo: ()=> Promise<void>;
    onPlayVideo: ()=> Promise<void>;
    handlePlayer: (status: boolean)=> void;
}

const chipsCommonStyles: React.CSSProperties = {
    width: "191px",
    height: "32px",
    outline: "none",
    marginTop: "10px",
    fontWeight: "600",
    fontSize: "14px",
    float: "right",
    borderRadius: "16px",
    textTransform: "none",
};

const StyledVideoPlayButton = styled(Button)(({ theme }) => ({
    ...chipsCommonStyles,
    backgroundColor: "#75C1E126",
    color: "#3D9FC9",
    "&:hover": {
        backgroundColor: "#75c1e13d",
    },
    [theme.breakpoints.only("md")]: {
        marginTop: "25px",
    },
    [theme.breakpoints.only("sm")]: {
        marginTop: "25px",
    },
}));

const StyledVideoRequestButton = styled(Button)(({ theme }) => ({
    ...chipsCommonStyles,
    backgroundColor: "#75C1E126",
    color: "#3D9FC9",
    "&:hover": {
        backgroundColor: "#75c1e13d",
    },
    [theme.breakpoints.only("md")]: {
        marginTop: "25px",
    },
    [theme.breakpoints.only("sm")]: {
        marginTop: "25px",
    },
}));

const StyledDownloadButton = styled(Button)(({ theme }) => ({
    ...chipsCommonStyles,
    backgroundColor: "#7EE17526",
    color: "#1AAE17",
    "&:hover": {
        backgroundColor: "#7ee1754a",
    },
    [theme.breakpoints.only("md")]: {
        marginTop: "25px",
    },
    [theme.breakpoints.only("sm")]: {
        marginTop: "25px",
    },
}));

const StyledFileUnavailableButton = styled(Button)(({ theme }) => ({
    ...chipsCommonStyles,
    width: "fit-content",
    backgroundColor: "#ffe69b99",
    color: "#f57c00",
    "&:hover": {
        backgroundColor: "#ffe69bc9",
    },
    [theme.breakpoints.only("md")]: {
        marginTop: "25px",
    },
    [theme.breakpoints.only("sm")]: {
        marginTop: "25px",
    },
}));

const StyledViewFootageButton = styled(Button)(({ theme }) => ({
    ...chipsCommonStyles,
    backgroundColor: "#75C1E126",
    color: "#3D9FC9",
    "&:hover": {
        backgroundColor: "#75c1e13d",
    },
    [theme.breakpoints.only("md")]: {
        marginTop: "25px",
    },
    [theme.breakpoints.only("sm")]: {
        marginTop: "25px",
    },
}));

const CustomizedProcessingVideoChip = styled(Chip)(({ theme }) => ({
    ...chipsCommonStyles,
    backgroundColor: "#FBEA5359",
    color: "#EEBA00",
    "& .MuiChip-label": {
        fontWeight: "600 !important",
        fontSize: "14px",
    },
    [theme.breakpoints.only("md")]: {
        marginTop: "25px",
    },
    [theme.breakpoints.only("sm")]: {
        marginTop: "25px",
    },
}));

const StyledStatusLoadingChip = styled(Chip)(({ theme }) => ({
    ...chipsCommonStyles,
    backgroundColor: "transparent",
    color: "#7A7A7A",
    "& .MuiChip-label": {
        fontWeight: "600 !important",
        fontSize: "14px",
    },
    [theme.breakpoints.only("md")]: {
        marginTop: "25px",
    },
    [theme.breakpoints.only("sm")]: {
        marginTop: "25px",
    },
}));

const StyledRetryIcon = styled(ReplayIcon)(() => ({
    fontSize: "18px",
    color: "#f57c00",
    marginRight: "10px",
    stroke: "#f57c00 !important",
    strokeWidth: "0.3px !important",
}));

const StyledStopIcon = styled(AiOutlineStop)(() => ({
    fontSize: "18px",
    color: "#f57c00",
    fontWeight: "600",
    marginLeft: "10px",
    strokeWidth: "20px !important",
    "& .MuiChip-icon": {
        marginLeft: "12px",
    },
}));

const StyledDownloadIcon = styled(BsDownload)(() => ({
    fontSize: "18px",
    color: "#1AAE17",
    fontWeight: "600",
    strokeWidth: "1px !important",
}));

const StyledPlayIcon = styled(FiPlay)(() => ({
    fontSize: "18px",
    color: "#3D9FC9",
    fontWeight: "600",
    strokeWidth: "2.5px !important",
}));

const StyledRetryCircularProgress = styled(CircularProgress)(() => ({
    color: "rgba(0, 0, 0, 0.26)",
    marginRight: "10px",
}));

const IncidentModalHeader = ({
    incidentId,
    location,
    lga,
    videoLinkLoading,
    playVideoLoading,
    downloadVideoLoading,
    requestVideoLoading,
    incidentFileStatus,
    fileStatusFetching,
    isRetentionPeriodExpired,
    locationId,
    onViewFootageClick,
    onRequestVideo,
    onDownloadVideo,
    onPlayVideo,
    handlePlayer,
}: IncidentModalHeaderProps) => {
    const [shouldRenderFootage, setShouldRenderFootage] = useState<boolean>(false);
    const [shouldRenderVideoRequest, setShouldRenderVideoRequest] = useState<boolean>(false);
    const { locations } = useSelector((state) => state.newLocation);
    
    useEffect(() => {
        const filteredLocation = locations.find((loc: Location) => loc.id === locationId);
        const { metadata } = filteredLocation || {};
        setShouldRenderFootage(!!metadata?.canViewMerakiFootage);
        setShouldRenderVideoRequest(!!metadata?.canRequestEdgeVideo);
    }, [locationId]);

    const handlePlayModalOpen = async () => {
        await onPlayVideo();
        handlePlayer(true);
    };

    const renderChips = () => {
        switch (incidentFileStatus) {
            case IncidentFileStatus.AVAILABLE:
            case IncidentFileStatus.EXPIRED: {
                const isPlayButtonDisabled =
                    playVideoLoading || fileStatusFetching || incidentFileStatus === IncidentFileStatus.EXPIRED;
                const playButton = (
                    <StyledVideoPlayButton
                        startIcon={<StyledPlayIcon color={isPlayButtonDisabled ? DISABLED_COLOR_CODE : "#3D9FC9"} />}
                        onClick={handlePlayModalOpen}
                        disabled={isPlayButtonDisabled}
                    >
                        {playVideoLoading ? `${IncidentVideoStatus.LOADING}...` : IncidentVideoAction.PLAY_VIDEO}
                    </StyledVideoPlayButton>
                );
                const isDownloadButtonDisabled =
                    downloadVideoLoading || fileStatusFetching || incidentFileStatus === IncidentFileStatus.EXPIRED;
                const downloadButton = (
                    <StyledDownloadButton
                        startIcon={
                            <StyledDownloadIcon color={isDownloadButtonDisabled ? DISABLED_COLOR_CODE : "#1AAE17"} />
                        }
                        onClick={onDownloadVideo}
                        disabled={isDownloadButtonDisabled}
                    >
                        {downloadVideoLoading
                            ? `${IncidentVideoStatus.LOADING}...`
                            : IncidentVideoAction.DOWNLOAD_VIDEO}
                    </StyledDownloadButton>
                );
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <ProtectedView filterScopes={[SCOPES.VIEW_FILES_INCIDENTS]} shouldHide>
                                {incidentFileStatus === IncidentFileStatus.EXPIRED ? (
                                    <IotVisionToolTips
                                        title={VIDEO_IS_NO_LONGER_AVAILABLE}
                                        arrow
                                        PopperProps={{ style: { whiteSpace: "nowrap" } }}
                                    >
                                        <div>{playButton}</div>
                                    </IotVisionToolTips>
                                ) : (
                                    playButton
                                )}
                            </ProtectedView>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <ProtectedView filterScopes={[SCOPES.DOWNLOAD_FILES_INCIDENTS]} shouldHide>
                                {incidentFileStatus === IncidentFileStatus.EXPIRED ? (
                                    <IotVisionToolTips
                                        title={VIDEO_IS_NO_LONGER_AVAILABLE}
                                        arrow
                                        PopperProps={{ style: { whiteSpace: "nowrap" } }}
                                    >
                                        <div>{downloadButton}</div>
                                    </IotVisionToolTips>
                                ) : (
                                    downloadButton
                                )}
                            </ProtectedView>
                        </Grid>
                    </Grid>
                );
            }

            case IncidentFileStatus.PENDING:
                return (
                    <CustomizedProcessingVideoChip
                        label={IncidentVideoStatus.PROCESSING_VIDEO}
                        icon={
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress sx={{ color: "#EEBA00" }} size={18} />
                            </Box>
                        }
                    />
                );

            case IncidentFileStatus.NOT_AVAILABLE:
                return (
                    <IotVisionToolTips
                        title={IncidentVideoAction.RETRY_VIDEO_REQUEST}
                        arrow
                        PopperProps={{ style: { whiteSpace: "nowrap" } }}
                    >
                        <StyledFileUnavailableButton
                            endIcon={
                                !isRetentionPeriodExpired && requestVideoLoading ? (
                                    <StyledRetryCircularProgress size={16} />
                                ) : (
                                    <StyledRetryIcon />
                                )
                            }
                            startIcon={
                                <StyledStopIcon
                                    color={requestVideoLoading ? DISABLED_COLOR_CODE : "#f57c00"}
                                />
                            }
                            disabled={requestVideoLoading}
                            onClick={onRequestVideo}
                        >
                            {IncidentVideoStatus.VIDEO_UNAVAILABLE}
                        </StyledFileUnavailableButton>
                    </IotVisionToolTips>
                );

            case IncidentFileStatus.LOADING:
                return (
                    <StyledStatusLoadingChip
                        label={`${IncidentVideoStatus.LOADING}...`}
                        icon={
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress sx={{ color: "#7A7A7A" }} size={16} />
                            </Box>
                        }
                    />
                );

            default: {
                const isRequestButtonDisabled = requestVideoLoading || fileStatusFetching || isRetentionPeriodExpired;
                const videoRequestButton = (
                    <StyledVideoRequestButton
                        startIcon={
                            <FiVideo
                                color={isRequestButtonDisabled ? DISABLED_COLOR_CODE : "#3D9FC9"}
                                size="18px"
                                fontWeight="600"
                            />
                        }
                        disabled={isRequestButtonDisabled}
                        onClick={onRequestVideo}
                    >
                        {requestVideoLoading ? `${IncidentVideoStatus.LOADING}...` : IncidentVideoAction.REQUEST_VIDEO}
                    </StyledVideoRequestButton>
                );
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <ProtectedView filterScopes={[SCOPES.CREATE_INCIDENTS_VIDEO]} shouldHide>
                                {isRetentionPeriodExpired ? (
                                    <IotVisionToolTips
                                        title={RETENTION_PERIOD_HAS_PASSED}
                                        arrow
                                        PopperProps={{ style: { whiteSpace: "nowrap" } }}
                                    >
                                        <div>{videoRequestButton}</div>
                                    </IotVisionToolTips>
                                ) : (
                                    videoRequestButton
                                )}
                            </ProtectedView>
                        </Grid>
                    </Grid>
                );
            }
        }
    };

    return (
        <Grid container item xs={12} sm={12} md={12} lg={12} width="868px" spacing={1}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography color="#21315B" fontSize="26px" fontWeight={600}>
                    Incident {incidentId}
                </Typography>
                <Typography color="#7A7A7A" fontSize="14px" fontWeight={400}>
                    {location}, {lga}
                </Typography>
            </Grid>
            {shouldRenderFootage && (
                <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5}>
                    <StyledViewFootageButton
                        startIcon={
                            <FiCamera
                                color={videoLinkLoading ? DISABLED_COLOR_CODE : "#3D9FC9"}
                                size="18px"
                                fontWeight="600"
                            />
                        }
                        disabled={videoLinkLoading}
                        onClick={() => onViewFootageClick()}
                    >
                        {videoLinkLoading ? `${INCIDENT_FOOTAGE_LOADING}...` : INCIDENT_FOOTAGE_VIEW}
                    </StyledViewFootageButton>
                </Grid>
            )}
            {shouldRenderVideoRequest && (
                <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} display="flex" justifyContent="flex-start">
                    {renderChips()}
                </Grid>
            )}
        </Grid>
    );
};

export default IncidentModalHeader;
